@font-face {
font-family: '__poppins_96538e';
src: url(/_next/static/media/916d3686010a8de2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__poppins_96538e';
src: url(/_next/static/media/5ae857df470a4ead-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__poppins_96538e';
src: url(/_next/static/media/d0511984dafaf382-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__poppins_96538e';
src: url(/_next/static/media/caee1ce8ed70f9d7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__poppins_Fallback_96538e';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_96538e {font-family: '__poppins_96538e', '__poppins_Fallback_96538e'
}.__variable_96538e {--font-poppins: '__poppins_96538e', '__poppins_Fallback_96538e'
}

@font-face {
font-family: '__gtUltra_724d45';
src: url(/_next/static/media/3b04a11b1366ed40-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtUltra_724d45';
src: url(/_next/static/media/f6cff335f5589682-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__gtUltra_Fallback_724d45';src: local("Arial");ascent-override: 85.34%;descent-override: 23.48%;line-gap-override: 0.00%;size-adjust: 105.44%
}.__className_724d45 {font-family: '__gtUltra_724d45', '__gtUltra_Fallback_724d45'
}.__variable_724d45 {--font-gtUltra: '__gtUltra_724d45', '__gtUltra_Fallback_724d45'
}

@font-face {
font-family: '__helveticaNow_46f590';
src: url(/_next/static/media/2d06a8f45c368ec6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__helveticaNow_46f590';
src: url(/_next/static/media/491f9253e07a8976-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__helveticaNow_46f590';
src: url(/_next/static/media/da916341756f7680-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__helveticaNow_Fallback_46f590';src: local("Arial");ascent-override: 103.85%;descent-override: 33.68%;line-gap-override: 0.00%;size-adjust: 106.88%
}.__className_46f590 {font-family: '__helveticaNow_46f590', '__helveticaNow_Fallback_46f590'
}.__variable_46f590 {--font-helveticaNow: '__helveticaNow_46f590', '__helveticaNow_Fallback_46f590'
}

